const Public_API_Key = "RxqAWS";

export function initKlaviyo() {
    return new Promise((resolve, reject) => {

        
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=" + Public_API_Key;
            js.onload = js.onreadystatechange = function () {
                js.onreadystatechange = js.onload = null;
                resolve("Klaviyo initialized.");
            };
            js.onerror = function () {
                js.onerror = null;
                reject();
            };
            (<any>fjs).parentNode.insertBefore(js, fjs);
        }(document, 'script', 'klaviyo'));
    });
}