import { imgixURL, bookingCourtsURL } from '@/constants';
import { i18n } from './i18n'
//import { store } from './store'
import auth from '@/plugins/auth';
import analytics from '@/plugins/analytics';
//import { initFacebookSdk } from '@/plugins/init-facebook-sdk';
//import { initAppleIDAuth } from '@/plugins/init-appleid.auth';
import AxiosService from '@/api-services/axios.service';
import notify from '@/plugins/notify';
const API_AUTHORIZATION = '/api/authorization';
const API_MYPAGE = '/api/MyPage';

export const mixins = {
    computed: {
        $isAuthorized: function () {
            return auth.isAuthorized();
        },
        $isAdmin: function () {
            return auth.isUserInRole("admin", "superadmin");
        },
        $isSubscriber: function () {
            return auth.isUserInRole("subsciber");
        },
        $isPartner: function () {
            return auth.isUserInRole("partner");
        },

        $isSuperAdmin: function () {
            return auth.isUserInRole("superadmin");
        },
    },
    methods: {
        $signIn(userToken, email, userId, appFirstName) {
            auth.signIn(userToken, email, userId, appFirstName);
            analytics.trackLogin("signIn: " + appFirstName);
        },
        $facebookSignIn: function () {
            // login with facebook then authenticate with the API to get a JWT auth token
            let _this = this;

            let SignIn = function (accessToken) {
                localStorage.setItem('FBAccessToken', accessToken);
                AxiosService.post(API_AUTHORIZATION + '/facebookSignIn', { AccessToken: accessToken })
                    .then(response => {
                        (<any>_this).$signIn(response.data.UserToken, "", response.data.UserId, response.data.AppFirstName);
                        if ((<any>_this).$route.params.redirect) {
                            (<any>_this).$router.push({ path: (<any>_this).params.redirect.toString() })
                        } else {
                            (<any>_this).$router.push({ name: 'FrontPage' });
                        }
                    }).catch(er => {
                        console.log(er.response.data);
                        notify.error(er.response.data);
                    });
            };

            (<any>window).FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    SignIn(response.authResponse.accessToken);
                } else {
                    (<any>window).FB.login(function (response) {
                        if (response.authResponse) {
                            SignIn(response.authResponse.accessToken);
                        } else {
                            console.log('User cancelled login or did not fully authorize.');
                        }
                    }, { scope: 'public_profile,email' });
                }
            });
        },
        $appleSignIn: function () {
            (<any>window).appleAsyncInit();
            let _this = this;
            var data = (<any>window).AppleID.auth.signIn().then(function (response) {
                AxiosService.post(API_AUTHORIZATION + '/appleIDSignIn', { Code: response.authorization.code, IdToken: response.authorization.id_token, State: response.authorization.state, RedirectUrl: window.location.origin })
                    .then(response => {
                        (<any>_this).$signIn(response.data.UserToken, "", response.data.UserId, response.data.AppFirstName);
                        if ((<any>_this).$route.params.redirect) {
                            (<any>_this).$router.push({ path: (<any>_this).params.redirect.toString() })
                        } else {
                            (<any>_this).$router.push({ name: 'FrontPage' });
                        }
                    }).catch(er => {
                        console.log(er.response.data);
                        notify.error(er.response.data);
                    });
            }, function (err) {
                console.error(err);
            });
        },
        $googleSignIn: function () {

        },
        $signOut() {
            auth.signOut();

            if (localStorage.getItem('FBAccessToken')) {
                (<any>window).FB.logout(function (response) {
                    console.log("user is now logged out");
                });
            }

        },
        $imgix: function (imgName, params) {
            if (!imgName) return "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
            return `${imgixURL}/${imgName}${params}`;
        },
        $slugify: function (text, ampersand = 'and') {
            if (!text) return "";
            return text.toString().toLowerCase()
                .replace(/[\s_]+/g, '-')
                .replace(/&/g, `-${ampersand}-`)
                .replace(/[^\w-]+/g, '')
                .replace(/--+/g, '-')
                .replace(/^-+|-+$/g, '')
        },
        $getHeadMeta: function (params) {
            let meta = new Array<any>();

            meta.push({ name: 'application-name', content: i18n.global.t('general.brand-name'), id: 'applicationname' });
            meta.push({ property: 'og:url', content: window.location.href, id: 'ogurl' });


            if (params.description) {
                meta.push({ name: 'description', content: params.description, id: 'desc' });
                // Twitter
                meta.push({ n: 'twitter:description', c: params.description, id: 'twitterdesc' });
                meta.push({ property: 'og:description', content: params.description, id: 'ogdesc' });

            };

            if (params.contentTitle) {
                // Twitter
                meta.push({ name: 'twitter:title', content: params.contentTitle, id: 'twittertitle' });
                // Google+ / Schema.org
                meta.push({ itemprop: 'name', content: params.contentTitle, id: 'itempropname' });
                meta.push({ itemprop: 'description', content: params.contentTitle, id: 'itempropdesc' });
                meta.push({ property: 'og:title', content: params.contentTitle, id: 'ogtitle' });
                // Facebook / Open Graph
                //meta.push({ property: 'fb:app_id', content: '123456789' });
                //meta.push({ property: 'og:title', content: params.contentTitle });

            };

            if (params.keywords) {
                meta.push({ name: 'keywords', content: params.keywords, id: 'keywords' });
            }

            if (params.imageurl) {
                meta.push({ property: 'og:image', content: params.imageurl, id: 'ogimage' });
            }


            //<meta name="verification" content = "00279900990c849091c73f294ab25088" > 

            //<meta name = "viewport" content = "width=device-width, height=device-height, initial-scale=1, maximum-scale=1, user-scalable=no" > 

            return meta;
        },
        $date: function (TimeStringUTC: string, format: string) {
            if (!TimeStringUTC) return "";

            var timeUTC = new Date(TimeStringUTC);
            return i18n.global.d(timeUTC, format);

            //FIXME: convert to local time doesn't work
            //let value = new Date(Date.UTC(timeUTC.getFullYear(), timeUTC.getMonth(), timeUTC.getDay(), timeUTC.getHours(), timeUTC.getMinutes(), timeUTC.getSeconds()));
            //return i18n.d(value, format);
        },
        $bookingCourtsLink() {
            let token = localStorage.getItem("Authorization");
            if (token) {
                return `${bookingCourtsURL}?token=${token}`;
            }
            return `${bookingCourtsURL}`;
        },
        $goToBookingCourts() {
            let _this = this;
            let bookingCourtsLink = _this.$bookingCourtsLink();
            AxiosService.getMultiParams(`${API_MYPAGE}/GetSquashUserData`, {}).then(response => {
                if (response?.data && response.data.Gender && response.data.BirthDate) {
                    window.open(bookingCourtsLink, '_blank');
                } else {
                    (<any>_this).$router.push({ name: 'SquashUserDataRequest', params: { callbackUrl: bookingCourtsLink } });
                }
            }).catch(er => {
                notify.axiosError(er);
            });
        },
    }

}