import { createRouter, createWebHistory } from 'vue-router'
import { i18n } from './i18n'
import auth from '@/plugins/auth';

const routes = [
    {
        name: 'FrontPage',
        path: i18n.global.t('Pages.FrontPage.path').toString(),
        alias: '/index.html',
        meta: { title: i18n.global.t('Pages.FrontPage.title') },
        component: () => import(/* webpackChunkName: "frontPage" */ './components/Website/FrontPage.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        meta: { title: "404 - " + i18n.global.t('general.brand-name') },
        component: () => import(/* webpackChunkName: "notFoundComponent" */ './components/NotFoundComponent.vue')
    },
    {
        name: 'Login',
        path: i18n.global.t('Pages.Login.path').toString(),
        meta: { title: i18n.global.t('Pages.Login.title') },
        component: () => import(/* webpackChunkName: "login" */ './components/Website/Login/Login.vue')
    },
    {
        name: 'SquashLogin',
        path: i18n.global.t('Pages.SquashLogin.path').toString(),
        meta: { title: i18n.global.t('Pages.SquashLogin.title') },
        component: () => import(/* webpackChunkName: "squashLogin" */ './components/Website/Login/SquashLogin.vue')
    },
    {
        name: 'SquashUserDataRequest',
        path: i18n.global.t('Pages.SquashUserDataRequest.path').toString(),
        meta: { title: i18n.global.t('Pages.SquashUserDataRequest.title') },
        component: () => import(/* webpackChunkName: "squashBirthRequest" */ './components/Website/Login/SquashUserDataRequest.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'CreateUser',
        path: i18n.global.t('Pages.CreateUser.path').toString(),
        meta: { title: i18n.global.t('Pages.CreateUser.title') },
        component: () => import(/* webpackChunkName: "login" */ './components/Website/Login/CreateUser.vue')
    },
    {
        name: 'CreateUserConfirm',
        path: i18n.global.t('Pages.CreateUserConfirm.path').toString(),
        meta: { title: i18n.global.t('Pages.CreateUserConfirm.title') },
        component: () => import(/* webpackChunkName: "Confirmations" */ './components/Confirmations/CreateUserConfirm.vue')
    },
    {
        name: 'OrderConfirm',
        path: i18n.global.t('Pages.OrderConfirm.path').toString(), /*+ '/:orderId',*/
        meta: { title: i18n.global.t('Pages.OrderConfirm.title') },
        props: true,
        component: () => import(/* webpackChunkName: "Confirmations" */ './components/Confirmations/OrderConfirm.vue')
    },
    {
        name: 'EventConfirm',
        path: i18n.global.t('Pages.EventConfirm.path').toString(),
        meta: { title: i18n.global.t('Pages.EventConfirm.title') },
        props: true,
        component: () => import(/* webpackChunkName: "Confirmations" */ './components/Confirmations/EventConfirm.vue')
    },
    {
        name: 'PasswordResetConfirm',
        path: i18n.global.t('Pages.PasswordResetConfirm.path').toString(),
        meta: { title: i18n.global.t('Pages.PasswordResetConfirm.title') },
        component: () => import(/* webpackChunkName: "Confirmations" */ './components/Confirmations/PasswordResetConfirm.vue')
    },
    {
        name: 'PasswordUpdated',
        path: i18n.global.t('Pages.PasswordUpdated.path').toString(),
        meta: { title: i18n.global.t('Pages.PasswordUpdated.title') },
        component: () => import(/* webpackChunkName: "Confirmations" */ './components/Confirmations/PasswordUpdated.vue')
    },
    {
        name: 'UnsubscribeConfirm',
        path: i18n.global.t('Pages.UnsubscribeConfirm.path').toString(),
        meta: { title: i18n.global.t('Pages.UnsubscribeConfirm.title') },
        props: true,
        component: () => import(/* webpackChunkName: "Confirmations" */ './components/Confirmations/UnsubscribeConfirm.vue')
    },
    {
        name: 'CreatedUserSendedMail',
        path: i18n.global.t('Pages.CreatedUserSendedMail.path').toString(),
        meta: { title: i18n.global.t('Pages.CreatedUserSendedMail.title') },
        component: () => import('./components/Website/Login/CreatedUserSendedMail.vue')
    },
    {
        name: 'CreatedUserSendedMailCheckout',
        path: i18n.global.t('Pages.CreatedUserSendedMailCheckout.path').toString(),
        meta: { title: i18n.global.t('Pages.CreatedUserSendedMailCheckout.title') },
        props: true,
        component: () => import('./components/Website/Login/CreatedUserSendedMailCheckout.vue')
    },
    {
        name: 'NewPW',
        path: i18n.global.t('Pages.NewPW.path').toString(),
        meta: { title: i18n.global.t('Pages.NewPW.title') },
        component: () => import(/* webpackChunkName: "login" */ './components/Website/Login/NewPW.vue')
    },
    {
        name: 'ForgetPW',
        path: i18n.global.t('Pages.ForgetPW.path').toString(),
        meta: { title: i18n.global.t('Pages.ForgetPW.title') },
        component: () => import(/* webpackChunkName: "login" */ './components/Website/Login/ForgetPW.vue')
    },
    {
        name: 'Catalog',
        path: i18n.global.t('Pages.Catalog.path').toString(),
        meta: { title: i18n.global.t('Pages.Catalog.title') },
        component: () => import(/* webpackChunkName: "catalog" */ './components/Website/Catalog.vue')
    },
    {
        name: 'CategoryCatalog',
        path: i18n.global.t('Pages.CategoryCatalog.path').toString(),
        meta: { title: i18n.global.t('Pages.CategoryCatalog.title') },
        component: () => import(/* webpackChunkName: "catalog" */ './components/Website/CategoryCatalog.vue')
    },
    {
        name: 'CategoryPage',
        alias: i18n.global.t('Pages.CategoryPage.path').toString(),
        path: i18n.global.t('Pages.CategoryPage.path').toString() + '/:slug/',
        meta: { title: i18n.global.t('Pages.Catalog.title') },
        component: () => import(/* webpackChunkName: "catalog" */ './components/Website/CategoryPage.vue')
    },
    {
        name: 'ProductPage',
        path: i18n.global.t('Pages.ProductPage.path') + '/:slug/',
        meta: { title: i18n.global.t('Pages.ProductPage.title') },
        component: () => import(/* webpackChunkName: "productPage" */ './components/Website/ProductPage.vue'),
    },
    {
        name: 'AboutUs',
        path: i18n.global.t('Pages.AboutUs.path').toString(),
        meta: { title: i18n.global.t('Pages.AboutUs.title') },
        component: () => import(/* webpackChunkName: "aboutUs" */ './components/Website/AboutUs.vue'),
        props: true
    },
    {
        name: 'FAQ',
        path: i18n.global.t('Pages.FAQ.path').toString(),
        meta: { title: i18n.global.t('Pages.FAQ.title') },
        component: () => import(/* webpackChunkName: "faq" */ './components/Website/FAQ.vue'),
    },
    {
        name: 'MyPage',
        path: i18n.global.t('Pages.MyPage.path').toString(),
        meta: { title: i18n.global.t('Pages.MyPage.title') },
        component: () => import(/* webpackChunkName: "myPage" */ './components/Website/MyPage/MyPage.vue'),
        beforeEnter: auth.checkAuthorization,
        props: true
    },
    {
        name: 'CheckOut',
        path: i18n.global.t('Pages.CheckOut.path').toString() + '/productId=:productId',
        meta: { title: i18n.global.t('Pages.CheckOut.title') },
        component: () => import(/* webpackChunkName: "checkOut" */ './components/CheckOut.vue'),
        props: true
    },
    {
        name: 'ProductIndex',
        path: i18n.global.t('Pages.ProductIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.ProductIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Product/ProductIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'ProductCategoryEdit',
        path: i18n.global.t('Pages.ProductCategoryEdit.path').toString() + '/:productId',
        meta: { title: i18n.global.t('Pages.ProductCategoryEdit.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Product/ProductCategoryEdit.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'DealCode',
        path: i18n.global.t('Pages.DealCode.path').toString(),
        meta: { title: i18n.global.t('Pages.DealCode.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/DealCode.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'OrderIndex',
        path: i18n.global.t('Pages.OrderIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.OrderIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/OrderIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'SubscriptionsIndex',
        path: i18n.global.t('Pages.SubscriptionsIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.SubscriptionsIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/SubscriptionsIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'SubscriptionDeliveriesIndex',
        path: i18n.global.t('Pages.SubscriptionDeliveriesIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.SubscriptionDeliveriesIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/SubscriptionDeliveriesIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'SubscriptionMails',
        path: i18n.global.t('Pages.SubscriptionMails.path').toString() + '/:subscriptionId',
        meta: { title: i18n.global.t('Pages.SubscriptionMails.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/SubscriptionMails.vue'),
        beforeEnter: auth.checkAuthorization,
        props: true
    },
    {
        name: 'ProductToShipIndex',
        path: i18n.global.t('Pages.ProductToShipIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.ProductToShipIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/ProductToShipIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'PartnerIndex',
        path: i18n.global.t('Pages.PartnerIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.PartnerIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/PartnerIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'PartnerEdit',
        path: i18n.global.t('Pages.PartnerEdit.path').toString() + '/:partnerId',
        meta: { title: i18n.global.t('Pages.PartnerEdit.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/PartnerEdit.vue'),
        props: true,
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'UsersInfo',
        path: i18n.global.t('Pages.UsersInfo.path').toString(),
        meta: { title: i18n.global.t('Pages.UsersInfo.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/UsersInfo.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'UserEdit',
        path: i18n.global.t('Pages.UserEdit.path').toString() + '/:userId',
        meta: { title: i18n.global.t('Pages.UserEdit.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/UserEdit.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'LogIndex',
        path: i18n.global.t('Pages.LogIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.LogIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/LogIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'MessageTemplateIndex',
        path: i18n.global.t('Pages.MessageTemplateIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.MessageTemplateIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/MessageTemplateIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'QueuedEmailIndex',
        path: i18n.global.t('Pages.QueuedEmailIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.QueuedEmailIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/QueuedEmailIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'ScheduleTaskIndex',
        path: i18n.global.t('Pages.ScheduleTaskIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.ScheduleTaskIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/ScheduleTaskIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'PartnerPage',
        path: i18n.global.t('Pages.PartnerPage.path').toString() + '/:slug/',
        meta: { title: i18n.global.t('Pages.PartnerPage.title') },
        component: () => import(/* webpackChunkName: "partnerPage" */ './components/Website/PartnerPage.vue')
    },
    {
        name: 'PartnerCatalogPage',
        path: i18n.global.t('Pages.PartnerCatalogPage.path').toString(),
        meta: { title: i18n.global.t('Pages.PartnerCatalogPage.title') },
        component: () => import(/* webpackChunkName: "partnerCatalogPage" */ './components/Website/PartnerCatalogPage.vue')
    },
    {
        name: 'AlreadySubscribed',
        path: i18n.global.t('Pages.AlreadySubscribed.path').toString(),
        meta: { title: i18n.global.t('Pages.AlreadySubscribed.title') },
        props: true,
        component: () => import(/* webpackChunkName: "checkOut" */ './components/Website/AlreadySubscribed.vue')
    },
    {
        name: 'GiftCertificate',
        path: i18n.global.t('Pages.GiftCertificate.path').toString(),
        meta: { title: i18n.global.t('Pages.GiftCertificate.title') },
        component: () => import(/* webpackChunkName: "giftCertificate" */ './components/Website/GiftCertificate.vue'),
        props: true
    },
    {
        name: 'BuyGiftCertificate',
        path: i18n.global.t('Pages.BuyGiftCertificate.path').toString() + "/amount=:amount",
        meta: { title: i18n.global.t('Pages.BuyGiftCertificate.title') },
        props: true,
        component: () => import(/* webpackChunkName: "giftCertificate" */ './components/Website/BuyGiftCertificate.vue')
    },
    {
        name: 'GiftCardConfirm',
        path: i18n.global.t('Pages.GiftCardConfirm.path').toString(),
        meta: { title: i18n.global.t('Pages.GiftCardConfirm.title') },
        props: true,
        component: () => import(/* webpackChunkName: "Confirmations" */ './components/Confirmations/GiftCardConfirm.vue')
    },
    {
        name: 'LoginAndCreateUser',
        path: i18n.global.t('Pages.LoginAndCreateUser.path').toString(),
        meta: { title: i18n.global.t('Pages.LoginAndCreateUser.title') },
        component: () => import(/* webpackChunkName: "login" */ './components/Website/Login/LoginAndCreateUser.vue')
    },
    {
        name: 'CategoryIndex',
        path: i18n.global.t('Pages.CategoryIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.CategoryIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/CategoryIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'EventsIndex',
        path: i18n.global.t('Pages.EventsIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.EventsIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Events/EventsIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'EventEdit',
        path: i18n.global.t('Pages.EventEdit.path').toString() + '/:eventId',
        meta: { title: i18n.global.t('Pages.EventEdit.title') },
        props: true,
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Events/EventEdit.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'EventsCatalog',
        path: i18n.global.t('Pages.EventsCatalog.path').toString(),
        meta: { title: i18n.global.t('Pages.EventsCatalog.title') },
        component: () => import(/* webpackChunkName: "events" */ './components/Website/EventsCatalog.vue')
    },
    {
        name: 'EventPage',
        path: i18n.global.t('Pages.EventPage.path').toString() + '/:slug/',
        meta: { title: i18n.global.t('Pages.EventPage.title') },
        props: true,
        component: () => import(/* webpackChunkName: "events" */ './components/Website/EventPage.vue')
    },
    {
        name: 'PostsCategoriesIndex',
        path: i18n.global.t('Pages.PostsCategoriesIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.PostsCategoriesIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Posts/PostsCategoriesIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'PostCategoryEdit',
        path: i18n.global.t('Pages.PostCategoryEdit.path').toString() + '/:postCategoryId',
        meta: { title: i18n.global.t('Pages.PostCategoryEdit.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Posts/PostCategoryEdit.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'PostsIndex',
        path: i18n.global.t('Pages.PostsIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.PostsIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Posts/PostsIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'PostEdit',
        path: i18n.global.t('Pages.PostEdit.path').toString() + '/:postId',
        meta: { title: i18n.global.t('Pages.PostEdit.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Posts/PostEdit.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'PostsCatalog',
        path: i18n.global.t('Pages.PostsCatalog.path').toString(),
        meta: { title: i18n.global.t('Pages.PostsCatalog.title') },
        component: () => import(/* webpackChunkName: "posts" */ './components/Website/PostsCatalog.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'PostPage',
        path: i18n.global.t('Pages.PostPage.path').toString() + '/:slug',
        meta: { title: i18n.global.t('Pages.PostPage.title') },
        props: true,
        component: () => import(/* webpackChunkName: "posts" */ './components/Website/PostPage.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'SubscribePage',
        path: i18n.global.t('Pages.SubscribePage.path').toString(),
        meta: { title: i18n.global.t('Pages.Login.title') },
        component: () => import(/* webpackChunkName: "subscribePage" */ './components/Website/SubscribePage.vue')
    },
    {
        name: 'Squash',
        path: i18n.global.t('Pages.Squash.path').toString(),
        meta: { title: i18n.global.t('Pages.Squash.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Squash/Squash.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'MappingIndex',
        path: i18n.global.t('Pages.MappingIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.MappingIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/Mapping/MappingIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'TinkCallback',
        path: i18n.global.t('Pages.TinkCallback.path').toString(),
        meta: { title: i18n.global.t('Pages.TinkCallback.title') },
        component: () => import(/* webpackChunkName: "tink" */ './components/Website/TinkCallback.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'ExternalSubScriptRunIndex',
        path: i18n.global.t('Pages.ExternalSubScriptRunIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.ExternalSubScriptRunIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/ExternalSubScriptRunIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'AppTaskServiceIndex',
        path: i18n.global.t('Pages.AppTaskServiceIndex.path').toString(),
        meta: { title: i18n.global.t('Pages.AppTaskServiceIndex.title') },
        component: () => import(/* webpackChunkName: "adminSystem" */ './components/AdminSystem/AppTaskServiceIndex.vue'),
        beforeEnter: auth.checkAuthorization
    },
    {
        name: 'CompanyCancellationConfirm',
        path: i18n.global.t('Pages.CompanyCancellationConfirm.path').toString(),
        meta: { title: i18n.global.t('Pages.CompanyCancellationConfirm.title') },
        component: () => import(/* webpackChunkName: "companyCancellationConfirm" */ './components/Confirmations/CompanyCancellationConfirm.vue')
    },
    {
        name: 'PostCompanyCancellationConfirm',
        path: i18n.global.t('Pages.PostCompanyCancellationConfirm.path').toString(),
        meta: { title: i18n.global.t('Pages.PostCompanyCancellationConfirm.title') },
        component: () => import(/* webpackChunkName: "postCompanyCancellationConfirm" */ './components/Confirmations/PostCompanyCancellationConfirm.vue')
    }
]

export const router = createRouter({
    history: createWebHistory(i18n.global.locale),
    routes: routes,
    scrollBehavior(to, from, savedPosition): any {
        if(savedPosition) {
            return savedPosition
        } else {
            (<any>document).getElementById("header").style.top = "0";
            (<any>document).getElementById("navbar").style.top = "0";
            return { left: 0, top: 0 }
        }
    }
})