import { createI18n } from 'vue-i18n'
import da from '@/locales/da.json'



const dateTimeFormats: any = {
    'en':  {
        short: {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        },
        datetime: {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        }
    },
    'da': {
        short: {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        },
        datetime: {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        },
    }
}

const numberFormats:any = {
    'da': {
        currency: {
            style: 'currency', currency: 'kr', notation: 'standard'
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2
        }
    },
    'FR': {
        decimal: {
            style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2
        },
        decimal2: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        }
    }
}

//VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
//    // this === VueI18n instance, so the locale property also exists here

//    //if (this.locale !== 'en') {
//    //    // proceed to the default implementation
//    //    return defaultImpl.apply(this, arguments)
//    //}

//    if (choice === 0) {
//        return 0;
//    }

//    const teen = choice > 10 && choice < 20;
//    const endsWithOne = choice % 10 === 1;

//    if (!teen && endsWithOne) {
//        return 1;
//    }

//    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
//        return 2;
//    }

//    return (choicesLength < 4) ? 2 : 3;
//}

//Vue.use(VueI18n)



export const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'da',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'da',
    messages: { da },
    datetimeFormats: dateTimeFormats,
    numberFormats
})