export class CategoryItemViewModel {
    Id: number;
    Name: string;
    ShortName: string;
    Slug: string;
    PC_PhotoUrl: string;
    PC_PhotoDescription: string;
    PC_BannerPhotoUrl: string;

    constructor(initializer ?: CategoryItemViewModel) {
        this.Id = initializer ? initializer.Id : -1;
        this.Name = initializer ? initializer.Name : "";
        this.ShortName = initializer ? initializer.ShortName : "";
        this.Slug = initializer ? initializer.Slug : "";
        this.PC_PhotoUrl = initializer ? initializer.PC_PhotoUrl : "";
        this.PC_PhotoDescription = initializer ? initializer.PC_PhotoDescription : "";
        this.PC_BannerPhotoUrl = initializer ? initializer.PC_BannerPhotoUrl : "";
    }
}