import { store } from '@/store';

export function getAuthorizationToken() {
    let token = localStorage.getItem("Authorization");
    return token;
};

function getUserRole() {
    let token = localStorage.getItem("Authorization")
    if (token == null)
        return;
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    //debugger;
    return JSON.parse(jsonPayload).role;
};

function isAuthorized() {
    if (!store.state.User || !store.state.User.UserId) {
        return false;
    }
    return getUserRole() != undefined ? true : false;
};

export default {
    getAuthorizationToken,
    getUserRole,
    isAuthorized,

    isUserInRole(...roles: string[]) {
        if (!isAuthorized()) {
            //debugger;
            return false;
        }
        if (!roles || roles.length < 1) {
            //debugger;
            return false;
        }
        let userRole = getUserRole();
        //debugger;
        if (!userRole) {
            return false;
        }
        if (Array.isArray(userRole)) {
            let found = userRole.some(r => roles.includes(r));
            return found;
        }
        if (typeof userRole == 'string') {
            let found = roles.includes(userRole);
            return found;
        }
        return false;
    },

    checkAuthorization(to, from, next) {
        if (!getUserRole()) next({ name: 'Login', params: { redirect: to.fullPath } })
        next()
    },

    signIn(userToken, email, userId, appFirstName) {
        localStorage.setItem('Authorization', userToken);
        localStorage.setItem('Username', email);
        localStorage.setItem('email', email);
        localStorage.setItem("UserId", userId);
        if (appFirstName) {
            localStorage.setItem("AppFirstName", appFirstName);
        } else {
            localStorage.removeItem("AppFirstName");
        }
        store.state.User.UserName = appFirstName;
        setTimeout(() => {
            store.state.User.UserId = userId;
        }, 500);
    },

    signOut() {
        localStorage.removeItem('Authorization');
        localStorage.removeItem('Username');
        localStorage.removeItem('email');
        localStorage.removeItem("UserId");
        //localStorage.removeItem("Role");
        localStorage.removeItem("AppFirstName");
        store.state.User.UserId = null;
        store.state.User.UserName = "";
        store.state.User.CustomerPhotoUrl = "";
    }
}