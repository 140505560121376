import { router } from '../router';
import { mixins } from '../mixins';
import { notify } from "@kyvg/vue3-notification";

export default {
    success(message: string) {
        notify({
            group: 'general',
            title: "Success",
            text: message,
            type: 'success',
            duration: 5000,
            speed: 1000,
        })
    },
    error(message: string) {
        notify({
            group: 'general',
            title: 'Error!',
            text: message,
            type: 'error',
            duration: 5000,
            speed: 1000,
        })
    },
    axiosError(error: any) {
        if (!error.response) {
            notify({
                group: 'general',
                title: error.name,
                text: error.message,
                type: 'error',
                duration: 5000,
                speed: 1000,
            });
            return;
        }

        if (error.response.status == 401) {
            mixins.methods.$signOut();
            router.push({ name: 'Login', params: { redirect: (<any>router).history.current.fullPath } });
            return;
        }

        let textMessage = typeof error.response.data === 'object' ? error.response.data.title : error.response.data;
        notify({
            group: 'general',
            title: `${error.name} ${error.response.status}`,
            text: textMessage,
            type: 'error',
            duration: 5000,
            speed: 1000,
        });
        //console.error(error.response);
    },
    info(message: string) {
        notify({
            group: 'general',
            title: "Info",
            text: message,
            type: 'info',
            duration: 5000,
            speed: 1000,
        })
    },
    warn(message: string) {
        notify({
            group: 'general',
            title: "Warning",
            text: message,
            type: 'warn',
            duration: 5000,
            speed: 1000,
        })
    }
};


