import Axios from 'axios';
import { RESOURCE_NAME } from '@/constants';
import { getAuthorizationToken } from '@/plugins/auth';

export default {
    
    getAll(API_ENDPOINT: string) {
        let token = getAuthorizationToken();
        return Axios.get(RESOURCE_NAME + API_ENDPOINT, { headers: { 'Authorization': 'Bearer ' + token } });
    },

    get(API_ENDPOINT: string, id: any) {
        let token = getAuthorizationToken();
        return Axios.get(`${RESOURCE_NAME + API_ENDPOINT}?id=${id}`, { headers: { 'Authorization': 'Bearer ' + token } });
    },

    getMultiParams(API_ENDPOINT: string, params: any) {
        let token = getAuthorizationToken();
        let headers = {
            'Authorization': 'Bearer ' + token
        }
        return Axios.get(`${RESOURCE_NAME + API_ENDPOINT}`, { params, headers });
    },

    create(API_ENDPOINT: string, data: any) {
        let token = getAuthorizationToken();
        return Axios.post(RESOURCE_NAME + API_ENDPOINT, data, { headers: { 'Authorization': 'Bearer ' + token } });
    },

    post(API_ENDPOINT: string, data: any) {
        let token = getAuthorizationToken();
        return Axios.post(`${RESOURCE_NAME + API_ENDPOINT}/`, data, { headers: { 'Access-Control-Allow-Origin': '*', 'Authorization': 'Bearer ' + token } });
    },

    postMultipart(API_ENDPOINT: string, data: any) {
        let token = getAuthorizationToken();
        return Axios.post(`${RESOURCE_NAME + API_ENDPOINT}/`, data, { headers: { 'Access-Control-Allow-Origin': '*', 'Authorization': 'Bearer ' + token,  'Content-Type': 'multipart/form-data'} });
    },

    update(API_ENDPOINT: string, data: any) {
        let token = getAuthorizationToken();
        return Axios.put(`${RESOURCE_NAME + API_ENDPOINT}/`, data, { headers: { 'Access-Control-Allow-Origin': '*', 'Authorization': 'Bearer ' + token } });
    },

    delete(API_ENDPOINT: string, id: any) {
        let token = getAuthorizationToken();
        return Axios.delete(`${RESOURCE_NAME + API_ENDPOINT}/${id}`, { headers: { 'Access-Control-Allow-Origin': '*', 'Authorization': 'Bearer ' + token } });
    },

    download(API_ENDPOINT: string, params: any) {
        let token = getAuthorizationToken();
        let headers = {
            'Authorization': 'Bearer ' + token
        }
        return Axios.get(`${RESOURCE_NAME + API_ENDPOINT}`, { params, headers, responseType: 'blob'});
    },
};