
export function initCookiebot() {
    return new Promise((resolve, reject) => {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://consent.cookiebot.com/uc.js";
            js.setAttribute('data-cbid', 'd22d7c72-ef0d-4ef3-96b6-b25be3129332');
            js.setAttribute('data-blockingmode', 'auto');
            js.onload = js.onreadystatechange = function () {
                js.onreadystatechange = js.onload = null;
                resolve("Cookiebot initialized.");
            };
            js.onerror = function () {
                js.onerror = null;
                reject();
            };
            (<any>fjs).parentNode.insertBefore(js, fjs);
        }(document, 'script', 'Cookiebot'));
    });
}