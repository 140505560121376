import Vue from 'vue';
import App from './App.vue'
import { router } from './router'
import { mixins } from './mixins';
import { i18n } from './i18n'
import { store } from './store'
import VueGtag from "vue-gtag";
import FacebookPixelAdapter from '@/plugins/facebookpixeladapter/facebookpixeladapter';

import { RESOURCE_NAME, enableGTag, ga4_MeasurementId, enableFacebookPixel, fbp_pixelId } from '@/constants';

//import(/* webpackChunkName: "jquery" */ 'jquery')
//import(/* webpackChunkName: "bootstrap" */ 'bootstrap')

import BootstrapVueNext from 'bootstrap-vue-next'
import { BPagination } from 'bootstrap-vue/src/components/pagination/index';
import { BTabs, BTab } from 'bootstrap-vue/src/components/tabs/index';
import { BFormSelect } from 'bootstrap-vue/src/components/form-select';
import { BFormCheckbox } from 'bootstrap-vue/src/components/form-checkbox';
import { BModal } from 'bootstrap-vue/src/components/modal';
import { BPopover } from 'bootstrap-vue/src/components/popover';

import Notifications from '@kyvg/vue3-notification'
import checkView from 'vue-check-view'


const app = Vue.createApp({
    extends: App
}).use(router);

app.mixin(mixins);

app.use(i18n).use(store);
app.use(BootstrapVueNext);
app.component('BPagination', BPagination);
app.component('BTabs', BTabs);
app.component('BTab', BTab);
app.component('BFormSelect', BFormSelect);
app.component('BFormCheckbox', BFormCheckbox);
app.component('BModal', BModal);
app.component('BPopover', BPopover);
app.use(Notifications);
app.use(checkView);

app.use(VueGtag, {
    //config: { id: ga_TrackingId },
    config: { id: ga4_MeasurementId },
    enabled: enableGTag,
    //appName: 'My application',
    //pageTrackerScreenviewEnabled: true,
    //onReady() {
    //    // ready
    //},
    //onBeforeTrack() {
    //    // before!
    //},
    //onAfterTrack() {
    //    // after!
    //},
}, router);
if (enableFacebookPixel) {
    FacebookPixelAdapter.init(fbp_pixelId);
    //Vue.use(VueFacebookPixel, {
    //    router: router,
    //    //debug: true,
    //    //excludeRoutes: ...
    //});
}

app.mount('#app');

