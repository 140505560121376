
    import { defineComponent } from 'vue';
    import { CategoryItemViewModel } from '@/models/Website/CategoryItemViewModel';
    import { imgixURL} from '@/constants';
    // import ScrollBooster from 'scrollbooster';
    import AxiosService from '@/api-services/axios.service';
    import notify from '@/plugins/notify';
/*    import { Trans } from '@/plugins/Translation';*/
    const API_CLASSIFICATION = '/api/Classification';
    const API_SUBSCRIPTION = '/api/Subscription';
    const API_TINK = '/api/Tink';

    export default defineComponent({
        data: function () {
            return {
                ready: false,
                notifyInfoMessage: this.$t('website.upload-photo-instead-of-standart'),
                visible: false,
                fixed: "top",
                showMenuSidebar: false,
                showAccountSidebar: false,
                categoriesCollapseVisible: false,
                categoriesDropdownVisible: false,
                myPageCollapseVisible: false,
                adminCollapseVisible: false,
                CategoryList: [],
                HasAnySquashSubscription: false
            }
        },
        computed: {
            //supportedLocales() {
            //    return Trans.supportedLocales
            //},
            customerPhoto() {
                return this.$store.state.User.CustomerPhotoUrl ? `${imgixURL}/${this.$store.state.User.CustomerPhotoUrl}?w=40&h=40&auto=compress,format&fit=crop` : "";
            },
            customerName() {
                return this.$store.state.User.UserName ? this.$store.state.User.UserName : localStorage.getItem('email');
            },
            showLogin() {
                return this.$route.path == this.$t('Pages.Login.path');
            },
            showCreateUser() {
                return this.$route.path == this.$t('Pages.CreateUser.path');
            }
        },
        watch: {
            '$store.state.ActiveLanguageId': function () {
                (<any>this).getCategoryList(this.$store.state.ActiveLanguageId);
            },
            $isAuthorized: function (newValue, oldValue) {
              if(newValue) {
                (<any>this).hasAnySquashSubscription();
              }
            }
        },
        methods: {
            //switchLocale(locale: any): any {
            //    if (this.$i18n.locale !== locale) {
            //        //const to = this.$router.resolve({ params: { locale } })
            //        return Trans.changeLocale(locale).then(() => {
            //            //this.$router.push(to.location)
            //        })
            //    }
            //},
            SignOut(): any {
                (<any>this).$signOut()
                this.$data.visible = false;
                this.$router.push({ name: 'Login' });
                this.$data.showAccountSidebar = false;

                
            },
            hideCollapse: function () {
                this.$data.visible = false;
            },
            handleScroll: function () {
                console.log('Scrolling');
            },
            scrollToTop() {
                if (this.$route.path == this.$t('Pages.FrontPage.path')) {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    this.$data.showMenuSidebar = false;
                    this.$data.showAccountSidebar = false;
                }
                else {
                    this.$router.push({ name: "FrontPage" });
                    this.$data.showMenuSidebar = false;
                    this.$data.showAccountSidebar = false;
                }
            },
            goToRouterLink: function (linkname) {
                this.$router.push({ name: linkname });
                this.$data.showMenuSidebar = false;
                this.$data.showAccountSidebar = false;
            },
            toContactInfo() {
               this.$router.push({ name: 'AboutUs', params: { Index: "1" } });
               this.$data.showMenuSidebar = false;
            },
            toMyPageWithHash(hash) {
                this.$router.push({ name: 'MyPage', hash: hash });
                this.$data.showMenuSidebar = false;
            },
            getCategoryList: function (languageId) {
                this.$data.ready = false;
                if (languageId) {
                    AxiosService.getMultiParams(`${API_CLASSIFICATION}/GetClassificationList`, { LanguageId: languageId})
                        .then(response => {
                            this.$data.ready = true;
                            this.$data.CategoryList = response.data.map(i => new CategoryItemViewModel(i));
                        }).catch(er => notify.axiosError(er))
                }
            },
            hasAnySquashSubscription: function () {
                if ((<any>this).$isAuthorized) {
                    this.$data.ready = false;
                    AxiosService.getMultiParams(`${API_SUBSCRIPTION}/HasAnySquashSubscription`, {})
                        .then(response => {
                          this.$data.ready = true;
                          this.$data.HasAnySquashSubscription = response.data;
                        }).catch(er => notify.axiosError(er));
                }
            },
            getCategoryPageRouterOptions(category) {
                return category.Slug ? { name: 'CategoryPage', params: { slug: category.Slug } } : { name: 'CategoryPage', params: { slug: (<any>this).$slugify(category.Name) }, query: { Id: category.Id } };
            },
            ConnectBankAccounts: function () {
                var viewportWidth = window.innerWidth;
                AxiosService.getMultiParams(API_TINK + '/ConnectBankAccounts',{ReturnUrl:`${location.origin}/da/console.tink/callback`}).then(response => {
                    if (viewportWidth < 768) {
                        window.location.href = response.data;
                    }
                    else {
                        window.open(response.data);
                    }
                    
                }).catch(er => {
                    notify.axiosError(er);
                });
            }
        },
        mounted() {
            var prevScrollpos = window.pageYOffset;
            var viewportWidth = window.innerWidth;
            (<any>window).onscroll = function() {
                var currentScrollPos = window.pageYOffset;
                if (currentScrollPos > 130) {
                    if (prevScrollpos > currentScrollPos) {
                        (<any>document).getElementById("header").style.top = "0";
                        (<any>document).getElementById("navbar").style.top = "0";
                    } else {
                        if (viewportWidth > 576) {
                            (<any>document).getElementById("header").style.top = "0";
                            (<any>document).getElementById("navbar").style.top = "-60px";
                        } else {
                            (<any>document).getElementById("header").style.top = "-60px";
                            (<any>document).getElementById("navbar").style.top = "-60px";
                        }
                    }
                }
                else {
                    (<any>document).getElementById("header").style.top = "0";
                }
                prevScrollpos = currentScrollPos;
            },

            // new ScrollBooster({
            //     viewport: document.querySelector('.mobile-navbar'),
            //     content: document.querySelector('.mobile-navbar-content'),
            //     scrollMode: 'transform',
            //     direction: 'horizontal',
            //     emulateScroll: true,
                
            // });

            (<any>this).getCategoryList(this.$store.state.ActiveLanguageId);
            (<any>this).hasAnySquashSubscription();
        }
    })
