import FacebookPixelAdapter from '@/plugins/facebookpixeladapter/facebookpixeladapter';
import { event } from 'vue-gtag'

export class gtagItemModel {
    item_id: string;
    item_name: string;
    currency: string;
    price: number;
    quantity: number;

    constructor(initializer) {
        this.item_id = initializer.item_id ? initializer.item_id : "";
        this.item_name = initializer.item_name ? initializer.item_name : "";
        this.currency = "DKK";// initializer.currency ? initializer.currency : "";
        this.price = initializer.price ? initializer.price : 0;
        this.quantity = initializer.quantity ? initializer.quantity : 0;
    }

    //items: [
    //    {
    //        item_id: "SKU_12345",
    //        item_name: "Stan and Friends Tee",
    //        //affiliation: "Google Store", //Partner name?
    //        //coupon: "SUMMER_FUN", //vaucher?
    //        currency: currency,
    //        //discount: 2.22,
    //        //index: 5,
    //        //item_brand: "Google", //Partner v2?
    //        //item_category: "Apparel", //classification Name?
    //        //item_category2: "Adult",
    //        //item_category3: "Shirts",
    //        //item_category4: "Crew",
    //        //item_category5: "Short sleeve",
    //        //item_list_id: "related_products",
    //        //item_list_name: "Related Products",
    //        //item_variant: "green",
    //        //location_id: "L_12345",
    //        price: price,
    //        quantity: 1
    //    }
    //],
}

export default {
    trackNewsletter(newValue: boolean) {
        console.table({ action: 'trackNewsletter', newValue: newValue })
        if (newValue === true) {
            //console.log("Newsletter on");
            FacebookPixelAdapter.track ('Subscribe', { });
        } else {
            //console.log("Newsletter off");
        }

    },

    trackCreatedAccount() {
        console.table({ action: 'trackCreatedAccount'})

        FacebookPixelAdapter.track('CreatedAccount');
    },
    trackCompleteRegistration() {
        console.table({ action: 'trackCompleteRegistration' })

        FacebookPixelAdapter.track('CompleteRegistration', {});
    },

    trackLogin( method: string) {
        console.table({ action: 'trackLogin', method: method})
        console.warn("gtag don't track Login yet");

        //event("login", {
        //    method: method
        //});
    },

    trackInitiateCheckout(price: number, currency: string, items: Array<gtagItemModel>) {
        currency = "DKK";
        console.table({ action: 'trackInitiateCheckout', price: price, currency: currency, items: items })

        FacebookPixelAdapter.track('InitiateCheckout', {
            value: price,
            currency: currency,
            content_ids: items.map(x => x.item_id),
            content_type: 'product',
        });

        event('begin_checkout', {
            currency: currency,
            value: price,
            //coupon: "SUMMER_FUN",
            items: items
        })
    },

    trackAddPaymentInfo(price: number, currency: string, items: Array<gtagItemModel>) {
        currency = "DKK";
        console.table({ action: 'trackAddPaymentInfo', price: price, currency: currency, items: items })

        event('add_payment_info', {
            currency: currency,
            value: price,
            //coupon: "SUMMER_FUN",
            payment_type: "Credit Card",
            items: items
        });
    },

    trackPurchase(price: number, currency: string, items: Array<gtagItemModel>, transactionId: string) {
        currency = "DKK";
        console.table({ action: 'purchase', price: price, currency: currency, items: items, transactionId: transactionId})

        FacebookPixelAdapter.track('Purchase', {
            value: price,
            currency: currency,
            content_ids: items.map(x => x.item_id),
            content_type: 'product',
        });

        event('purchase', {
            currency: currency,
            transaction_id: transactionId, // "O_1455",
            value: price,
            affiliation: "suubz.com",
            //coupon: "SUMMER_FUN", //vaucher?
            //shipping: 0,
            //tax: 2.22,
            items: items
        });
    },

    trackUnsubscribe(productIds: Array<number>, price: number, currency: string) {
        //console.log("Unsubscribe: productIds=[" + productIds + "], price=" + price + ", currency=" + currency);
    },

    trackViewContent(price: number, currency: string, items: Array<gtagItemModel>) {
        currency = "DKK";
        console.table({ action: 'trackViewContent', price: price, currency: currency, items: items })

        FacebookPixelAdapter.track('ViewContent', {
            value: price,
            currency: currency,
            content_ids: items.map(x => x.item_id),
            content_type: 'product',
        });

        event('view_item', {
            currency: currency,
            value: price,
            items: items
        });
    },

    trackViewItemList(itemListId: string, itemListName: string, items: Array<gtagItemModel>) {
        console.table({ action: 'trackViewItemList', itemListId: itemListId, itemListName: itemListName, items: items })

        event('view_item_list', {
            item_list_id: itemListId, //catalog_
            item_list_name: itemListName, //Catalog - CategoryName  // Category - C_Name //Partner  - Name
            items: items
        });
    },

    trackSelectItem(itemListId: string, itemListName: string, items: Array<gtagItemModel>) {
        console.log("select_item");
        //will be implemented later
        //event('select_item', {
        //    item_list_id: itemListId,
        //    item_list_name: itemListName,
        //    items: items
        //});
    },
    trackAddShippingInfo() {
        console.log("add_shipping_info");
        //will be implemented later
        //    event('add_shipping_info', {
        //        currency: "DKK",
        //        value: 7.77,
        //        coupon: "SUMMER_FUN",
        //        shipping_tier: "Ground",
        //        items: [
        //            {
        //                item_id: "SKU_12345",
        //                item_name: "Stan and Friends Tee",
        //                affiliation: "Google Store",
        //                coupon: "SUMMER_FUN",
        //                currency: "DKK",
        //                discount: 2.22,
        //                index: 5,
        //                item_brand: "Google",
        //                item_category: "Apparel",
        //                item_category2: "Adult",
        //                item_category3: "Shirts",
        //                item_category4: "Crew",
        //                item_category5: "Short sleeve",
        //                item_list_id: "related_products",
        //                item_list_name: "Related Products",
        //                item_variant: "green",
        //                location_id: "L_12345",
        //                price: 9.99,
        //                quantity: 1
        //            }
        //        ]
        //    });
    },
    trackSelectPromotion() {
        console.log("select_promotion");
        //will be implemented later
        //    event('select_promotion', {
        //        creative_name: "Summer Banner",
        //        creative_slot: "featured_app_1",
        //        location_id: "L_12345",
        //        promotion_id: "P_12345",
        //        promotion_name: "Summer Sale",
        //        items: [
        //            {
        //                item_id: "SKU_12345",
        //                item_name: "Stan and Friends Tee",
        //                affiliation: "Google Store",
        //                coupon: "SUMMER_FUN",
        //                creative_name: "summer_banner2",
        //                creative_slot: "featured_app_1",
        //                currency: "DKK",
        //                discount: 2.22,
        //                index: 5,
        //                item_brand: "Google",
        //                item_category: "Apparel",
        //                item_category2: "Adult",
        //                item_category3: "Shirts",
        //                item_category4: "Crew",
        //                item_category5: "Short sleeve",
        //                item_list_id: "related_products",
        //                item_list_name: "Related Products",
        //                item_variant: "green",
        //                location_id: "L_12345",
        //                price: 9.99,
        //                promotion_id: "P_12345",
        //                promotion_name: "Summer Sale",
        //                quantity: 1
        //            }
        //        ],
        //    });
    },
    trackViewPromotion() {
        console.log("view_promotion");
        //will be implemented later
        //event('view_promotion', {
        //    creative_name: "Summer Banner",
        //    creative_slot: "featured_app_1",
        //    location_id: "L_12345",
        //    promotion_id: "P_12345",
        //    promotion_name: "Summer Sale",
        //    items: [
        //        {
        //            item_id: "SKU_12345",
        //            item_name: "Stan and Friends Tee",
        //            affiliation: "Google Store",
        //            coupon: "SUMMER_FUN",
        //            creative_name: "summer_banner2",
        //            creative_slot: "featured_app_1",
        //            currency: "DKK",
        //            discount: 2.22,
        //            index: 5,
        //            item_brand: "Google",
        //            item_category: "Apparel",
        //            item_category2: "Adult",
        //            item_category3: "Shirts",
        //            item_category4: "Crew",
        //            item_category5: "Short sleeve",
        //            item_list_id: "related_products",
        //            item_list_name: "Related Products",
        //            item_variant: "green",
        //            location_id: "L_12345",
        //            price: 9.99,
        //            promotion_id: "P_12345",
        //            promotion_name: "Summer Sale",
        //            quantity: 1
        //        }
        //    ]
        //});
    },
};


