
    import { defineComponent } from 'vue';

    export default defineComponent({
        data: function () {
            return {

            }
        },
        methods: {

        }
    })
