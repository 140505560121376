
    import { defineComponent } from 'vue';
    
    import notify from '@/plugins/notify';
    import AxiosService from '@/api-services/axios.service';
    import NavBar from './components/NavBar.vue';
    import ExpiredConnectionModal from './components/Website/ExpiredConnectionModal.vue';
    import Footer from './components/Footer.vue';
    import { initKlaviyo } from '@/plugins/init-klaviyo';

    import { initCookiebot } from '@/plugins/init-cookiebot';
/*    import checkView from 'vue-check-view'*/
    import { addBackToTop } from 'vanilla-back-to-top'

    import { set } from 'vue-gtag'

    addBackToTop({
        innerHTML: '<i class="ico ico-totop"></i>',
    })

    export default defineComponent({
        data: function () {
            return {
                Languages: [],
            }
        },
        created() {
            window.addEventListener('resize', (<any>this).handleResize);
            (<any>this).handleResize();
        },
        mounted() {
            (<any>this).getGeneralData();
            setTimeout(() => {
                initCookiebot();
            }, 1000);

            setTimeout(() => {
                initKlaviyo();
            }, 500);
        },
        methods: {
            handleResize() {
                this.$store.state.windowWidth = window.innerWidth;
                this.$store.state.windowHeight = window.innerHeight;
            },
            getGeneralData() {
                AxiosService.getAll('/api/GeneralData/getData')
                    .then(response => {
                        this.$store.state.Languages = response.data.Languages;
                        this.$store.state.CloudfrontURL = response.data.CloudfrontURL;
                        this.$store.state.User.CustomerPhotoUrl = response.data.CustomerPhotoUrl;
                        this.$store.state.ActiveConnectionToBank = response.data.ActiveConnectionToBank;
                        this.$store.state.HasExpiredConnectionToBank = response.data.HasExpiredConnectionToBank;
                        if (response.data.ActiveLanguageId) {
                            this.$store.state.ActiveLanguageId = response.data.ActiveLanguageId;
                            localStorage.setItem("ActiveLanguageId", response.data.ActiveLanguageId);
                        } else {
                            // FIXME :Multi-Lang: this.$store.state.ActiveLanguageId = defaultLanguageId (2002 id)
                            //this.$store.state.ActiveLanguageId = defaultLanguageId;
                            console.error("ActiveLanguageId");
                        };
                        this.$store.state.User.UserId = response.data.UserId;
                        if (!response.data.UserId) {
                            localStorage.clear()
                            localStorage.setItem("RememberMe", "true")
                        }
                        if (response.data.UserId && response.data.UserId != '') {
                            // Set the user ID using signed-in user_id.
                            set({
                                'user_id': response.data.UserId
                            });
                        } else {
                            (<any>this).$signOut();
                        }
                    }).catch(er => notify.axiosError(er));
            }
        },
        components: {
            NavBar,
            Footer,
            addBackToTop,
            ExpiredConnectionModal
        },
        watch: {
            '$store.state.User.UserId': function () {
                if (this.$store.state.User.UserId) (<any>this).getGeneralData();
            }
        },
    })
