import { RESOURCE_NAME } from '@/constants';
import { createStore } from 'vuex'

export const store = createStore({
    state() {
        return {
            ActiveLanguageId: Number(localStorage.getItem("ActiveLanguageId")) || null,
            Languages: [],
            CloudfrontURL: "",
            ActiveConnectionToBank: false,
            HasExpiredConnectionToBank: false,
            RESOURCE_NAME: RESOURCE_NAME,
            User: {
                UserId: localStorage.getItem("UserId") || null,
                CustomerPhotoUrl: "",
                UserName: localStorage.getItem("AppFirstName") ? localStorage.getItem("AppFirstName") : localStorage.getItem("Username"),
            },
            OverlayOptions: {
                opacity: 0.85,
                variant: "secondary",
                blur: "2px",
                spinner_variant: "primary"
            },
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            showFooter: true
        }
    }
});