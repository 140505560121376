
import { defineComponent } from 'vue';
import notify from '@/plugins/notify';
import AxiosService from '@/api-services/axios.service';

const API_TINK = '/api/Tink';

export default defineComponent({
    data: function () {
        return {
            showBankConnectionModal: false,
        }
    },
    watch: {
        '$store.state.HasExpiredConnectionToBank': function (newValue) {
            if (newValue == true) {
                (<any>this).ShowExpireConnectionModal();
            }
        },
    },
    mounted() {
        (<any>this).ShowExpireConnectionModal();
    },
    methods: {
        ConnectBankAccounts: function () {
            var viewportWidth = window.innerWidth;
            AxiosService.getMultiParams(API_TINK + '/ConnectBankAccounts',{ReturnUrl:`${location.origin}/da/console.tink/callback`}).then(response => {
                if (viewportWidth < 768) {
                    window.location.href = response.data;
                }
                else {
                    window.open(response.data);
                }
            }).catch(er => {
                notify.axiosError(er);
            });
        },
        ShowExpireConnectionModal: function () {
            let needShowModalExpiredConnection = !Boolean(localStorage.getItem("needShowModalExpiredConnection") == 'false');
            if (this.$store.state.HasExpiredConnectionToBank == true && needShowModalExpiredConnection) {
                this.$data.showBankConnectionModal = true;
            }
        },
        CloseExpireConnectionModal: function () {
            localStorage.setItem("needShowModalExpiredConnection", "false");
            this.$data.showBankConnectionModal = false;
        },
    }
})
